<template>
  <v-navigation-drawer class="s-navigation-drawer" v-bind="$attrs"
    ><slot
  /></v-navigation-drawer>
</template>

<script>
export default {
  name: 'SNavigationDrawer',
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

::v-deep {
  .v-navigation-drawer__border {
    display: none;
  }

  .v-navigation-drawer__content {
    background: var(--v-light-base) !important;
    border-right: 1px solid rgba(221, 221, 221, 0.6) !important;
    padding: 0 1.8vw;
  }
}
</style>
